import * as React from "react"
import { useState } from "react"
import { Button, Checkbox, FormControlLabel, makeStyles, TextField, Typography } from "@material-ui/core"
import AlternateEmailIcon from "@material-ui/icons/AlternateEmail"
import PhoneIcon from "@material-ui/icons/Phone"
import PersonIcon from "@material-ui/icons/Person"
import ChatIcon from "@material-ui/icons/Chat"
import AlphaButton from "../alpha-button/alpha-button"
import Picture from "../picture/picture"
import { send } from "emailjs-com"
import { navigate } from "gatsby"

const useStyles = makeStyles((theme) => ({
  divForm: {
    padding: 25,
    borderRadius: 8
  },
  divRow: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    paddingTop: 10,
    paddingBottom: 10
  },
  divRowIcon: {
    paddingRight: 10,
    width: "45px",
    color: "#fff"
  },
  divRowField: {
    width: "95%"
  },
  input: {
    backgroundColor: "white"
  },
  divCheckboxAndButton: {
    display: "flex",
    justifyContent: "center",
    paddingTop: 30,
    paddingBottom: 10
  },
  divCheckbox: {
    width: "60%"
  },
  divButton: {
    width: "40%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  button: {
    paddingTop: 5,
    paddingBottom: 5,
    paddingLeft: 100,
    paddingRight: 100,
    backgroundImage: "linear-gradient(to right, #54B97D , #4CAAC3)"
  },
  img: {
    width: 70,
    height: 22,
    marginBottom: 0
  },
  aszfLabel: {
    fontSize: 13
  },
  root: {
    width: "100%",
    background: "rgba(200, 200, 200, 0.2)",
    borderRadius: "8px",
    marginTop: '15px'
  },
  chatIconRoot: {
    alignSelf: "flex-start"
  },
  innerDiv: {
    width: '80%',
    margin: 'auto',
    [theme.breakpoints.down(600)]: {
      width: '100%'
    }
  },
}))

const SeoAuditForm = () => {
  const classes = useStyles()
  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [mobilePhone, setMobilePhone] = useState("")
  const [message, setMessage] = useState("")

  const onSubmit = (e) => {
    e.preventDefault()
    send(
      "service_k2n6bj5",
      "template_z4gw8mx",
      {
        name: name,
        email: email,
        mobilePhone: mobilePhone,
        message: message
      },
      "user_pqGIrsqeuBQqvJitpknJf"
    )
      .then((response) => {
        console.log("SUCCESS!", response.status, response.text)
        navigate("/sikeres-kapcsolatfelvetel").then(r => {
        })

      })
      .catch((err) => {
        console.log("FAILED...", err)
      })
  }

  return <div className={classes.root}>
    <div className={classes.innerDiv}>
      <form onSubmit={onSubmit}>
        <div className={classes.divForm}>
          <div className={classes.divRow}>
            <div className={classes.divRowIcon}><PersonIcon /></div>
            <div className={classes.divRowField}>
              <TextField variant="outlined"
                         placeholder="Név*"
                         size="small"
                         fullWidth
                         InputProps={{
                           className: classes.input
                         }}
                         value={name}
                         onChange={e => setName(e.target.value)}
              />
            </div>
          </div>
          <div className={classes.divRow}>
            <div className={classes.divRowIcon}><AlternateEmailIcon /></div>
            <div className={classes.divRowField}>
              <TextField variant="outlined"
                         placeholder="E-mail cím*"
                         size="small"
                         fullWidth
                         InputProps={{
                           className: classes.input
                         }}
                         value={email}
                         onChange={e => setEmail(e.target.value)}
              />
            </div>
          </div>
          <div className={classes.divRow}>
            <div className={classes.divRowIcon}><PhoneIcon /></div>
            <div className={classes.divRowField}>
              <TextField variant="outlined"
                         placeholder="Telefonszám*"
                         size="small"
                         fullWidth
                         InputProps={{
                           className: classes.input
                         }}
                         value={mobilePhone}
                         onChange={e => setMobilePhone(e.target.value)}
              />
            </div>
          </div>
          <div className={classes.divRow}>
            <div className={`${classes.divRowIcon} ${classes.chatIconRoot}`}><ChatIcon /></div>
            <div className={classes.divRowField}>
              <TextField variant="outlined"
                         placeholder="Üzenet írása..."
                         size="small"
                         fullWidth
                         multiline
                         InputProps={{
                           className: classes.input
                         }}
                         rows={3}
                         value={message}
                         onChange={e => setMessage(e.target.value)}
              />
            </div>
          </div>
          <div className={classes.divCheckboxAndButton}>
            <div className={classes.divButton}>
              <AlphaButton className={classes.button} variant="contained" type="submit">
                Elküldöm
              </AlphaButton>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
}

export default SeoAuditForm
