import * as React from "react"
import Layout from "../components/layout"
import { makeStyles } from "@material-ui/core"
import MetaData from "../components/metadata"
import StructuredData from "../components/structured-data"
import OtherReferencesSlider from "../components/other-references-slider/other-references-slider"
import SeoAuditContent from "../components/seo-audit/seo-audit-content"
import SeoAuditWave from "../components/seo-audit/seo-audit-wave"

const useStyles = makeStyles((theme) => ({
}))

const SeoAuditPage = (props) => {
  const classes = useStyles()

  return <Layout>
    <MetaData title={'SeoAudit'} url={props.location.href} description={"A keresőmotorok számos tényezőre támaszkodnak a rangsorolásához.Átnézzük az összes szempont alapján, hogy segítsünk azonosítani azokat a problémákat, amik esetleg visszafoghatják a webhelyét "}/>
    <StructuredData title={'SeoAudit'} url={props.location.href} description={"A keresőmotorok számos tényezőre támaszkodnak a rangsorolásához.Átnézzük az összes szempont alapján, hogy segítsünk azonosítani azokat a problémákat, amik esetleg visszafoghatják a webhelyét "}/>
    <SeoAuditWave/>
    <SeoAuditContent/>
    <OtherReferencesSlider/>
  </Layout>
}

export default SeoAuditPage
