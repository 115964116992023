import * as React from "react"
import { Helmet } from "react-helmet"

const Picture = (props: PictureProps) => {
  const { className, alt, src, preload, lazy, preloadAbove } = props
  const sizes = props.sizes?.sort() || []

  const getHref = (type: string, size = 0) => {
    const sizePart = size ? `-w${size}` : '';
    return `/images/${src}${sizePart}.${type}`
  }

  const lazyProps = lazy ? { lazy: "lazy" } : {}

  function defaultImagePreloadMedia(): string {
    if (!sizes?.length && !preloadAbove) {
      return ''
    }
    if (preloadAbove) {
      return `(min-width:${preloadAbove}px)`
    }
    return `(min-width:${sizes[sizes.length - 1]}px)`
  }

  return <>
    <picture>
      {sizes?.map((size, i) => <React.Fragment key={i}>
        <source media={`(max-width:${size}px)`} srcSet={getHref('webp', size)} type="image/webp" />
        <source media={`(max-width:${size}px)`} srcSet={getHref("png", size)} type="image/png" />
      </React.Fragment>)}
      <source srcSet={getHref('webp')} type="image/webp" />
      <img src={getHref('png')} className={className} alt={alt} {...lazyProps} />
    </picture>

    {preload &&
    <Helmet>
      {sizes?.map((size, i) =>
        <link
          rel="preload"
          as="image"
          href={getHref("webp", size)}
          media={`(max-width:${size}px)${i > 0 ? ` and (min-width:${sizes[i - 1]}px)` : ""}`}
        />
      )}
        <link
          rel="preload"
          as="image"
          href={getHref('webp')}
          media={defaultImagePreloadMedia()}
          />
      </Helmet>
    }
  </>
}

interface PictureProps {
  className?: string
  alt: string
  src: string
  sizes?: number[]
  preload?: boolean
  lazy?: boolean
  preloadAbove?: number
}

export default Picture
