import * as React from "react"
import { Container, makeStyles, Typography, useMediaQuery } from "@material-ui/core"
import { Box, Grid } from "@mui/material"
import PageFunctionTick from "../reference-page/page-function-tick"

const useStyles = makeStyles((theme) => ({
  svg: {
    maxHeight: 480,
    marginBottom: -10,
    width: "100%"
  },
  divSvg: {
    height: "100%",
    overflow: "hidden",
    //backgroundColor: "#ECF2F5",
    width: "100%",
    gridColumn: "1 / 1",
    gridRow: "1 / 1",
    minHeight: 360,
    backdropFilter: "blur(8px)",
    [theme.breakpoints.down(1200)]: {
      backdropFilter: "blur(0px)",
    },
    [theme.breakpoints.down(905)]: {
      display: "flex",
      alignItems: "flex-end"
    },
    zIndex: 10
  },
  divTitle: {
    overflow: "hidden",
    //backgroundColor: "#ECF2F5",
    width: "100%",
    gridColumn: "1 / 1",
    gridRow: "1 / 1",
    minHeight: 360,
    [theme.breakpoints.down(905)]: {
      display: "flex",
      alignItems: "flex-end"
    },
    zIndex: 11
  },
  grid: {
    background: 'rgba(255, 255, 255, 0.2)',
    backdropFilter: 'blur(20px)',
    borderRadius: '10px',
    padding: '40px 20px',
    color: '#fff',
    textAlign: 'right',
    [theme.breakpoints.down(940)]: {
      textAlign: 'center',
    }
  },
  divImg: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    paddingTop: 5
  },
  divTop: {
    display: "grid",
    gridTemplate: "1fr/1fr",
    placeItems: "center",
    maxHeight: 480,
    marginTop: 80,
    [theme.breakpoints.down(940)]: {
      paddingTop: 40,
    }
  },
  logoImage: {
    marginBottom: 0
  },
  bgWave: {
    stroke: "none",
    fill: "#ECF2F5"
  },
  divIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  icon: {
    width: "240px",
    height: "240px",
    marginBottom: 0,
    [theme.breakpoints.down(400)]: {
      width: "200px",
      height: "200px",
    }
  },
  title: {
    fontSize: "48px",
    fontWeight: 700,
    [theme.breakpoints.down(600)]: {
      fontSize: '32px',
      textAlign: 'center'
    },
    [theme.breakpoints.down(350)]: {
      fontSize: '26px',
      textAlign: 'center'
    }
  },
  subTitle: {
    [theme.breakpoints.down(600)]: {
      textAlign: 'center'
    }
  }
}))

const SeoAuditWave = () => {
  const classes = useStyles()
  const matches = useMediaQuery("(min-width:1200px)")
  const bg = "/images/seo_bg.png"

  return <div className={classes.divTop}
              style={{ background: `url(${bg}) no-repeat center center`, backgroundSize: "cover" }}>
    <div className={classes.divSvg} style={matches ? { background: `url(${bg}) no-repeat center center` } : {}}>
      <svg viewBox="0 0 370 150" preserveAspectRatio="none"
           className={classes.svg}>
        <path
          d="M0,106 C210,215 399,-150 500,258 L500,150 L0,150 Z"
          className={classes.bgWave} />
      </svg>
    </div>
    <Container className={classes.divTitle}>
      <Grid container className={classes.grid}>
        <Grid item xs={12} md={6}>
          <Typography className={classes.title}>Ingyenes SEO audit jelentéskészítő</Typography>
          <Typography style={{fontSize: '18px'}} className={classes.subTitle}>Adjon meg egy URL-címet, és mi elkészítünk egy ingyenes webhelyelemzést!</Typography>
        </Grid>
        <Grid item xs={12} md={6} className={classes.divIcon}>
          <img src={"/images/seo_audit.png"} alt={"Seo Audit"} className={classes.icon} />
        </Grid>
      </Grid>
    </Container>
  </div>
}

export default SeoAuditWave
