import * as React from "react"
import { Box, Container, Grid, makeStyles, Typography } from "@material-ui/core"
import SeoAuditForm from "./seo-audit-form"

const useStyles = makeStyles((theme) => ({
  divAll: {
    // backgroundImage: "linear-gradient(to right, #00B0B3 , #00B790)",
    width: "100%",
    height: "auto",
    paddingTop: 25,
    paddingBottom: 25,
    backgroundImage: `url("/images/wave_bg_6.jpg")`,
    transition: "background 5s linear",
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    [theme.breakpoints.up(1000)]: {
      marginTop: '-30px'
    },
    [theme.breakpoints.down(420)]: {
      paddingTop: '62px'
    }
  },
  gridItem: {
    padding: "40px",
    [theme.breakpoints.down(600)]: {
      padding: "5px 15px"
    }
  },
  title: {
    fontSize: "36px",
    [theme.breakpoints.down(600)]: {
      fontSize: '28px'
    }
  }
}))

const SeoAuditContent = () => {
  const classes = useStyles()

  return <div className={classes.divAll}>
    <Container>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <Box className={classes.gridItem}>
            <Typography color={"primary"} className={classes.title}>Átfogó audit -</Typography>
            <Typography className={classes.title}>White Label PDF jelentés</Typography>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} style={{ color: "#808080", fontSize: '18px' }}>
          <Box className={classes.gridItem}>
            A keresőmotorok számos tényezőre támaszkodnak a rangsorolásához.
            Átvizsgáljuk az összes szempontot, hogy segíteni tudjunk azonosítani azon problémákat, amelyek
            visszafoghatják webhelyét
          </Box>
        </Grid>
      </Grid>
      <SeoAuditForm />
    </Container>
  </div>
}

export default SeoAuditContent